import * as React from "react";
import ParticleComponent from "../components/particles";

import Layout from "../components/layout";
import Footer from "../components/Footer";
import about_img from "../images/numericaideas.png";

const dataSEO = {
  title: "Privacy Policy",
  description: "NumericaIdeas's Privacy Policy",
  image: about_img,
  url: "https://www.numericaideas.com/privacy-policy",
};

class PrivacyPolicyPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout seoData={dataSEO}>
        <ParticleComponent>
          <div id="main" style={{ display: "flex", width: "100%" }}>
            <article
              id="privacy-policy"
              className="active timeout"
              style={{ width: "90%" }}
            >
              
              <strong> Privacy Policy </strong>
              <p>
                Numerica Ideas LTD("Ni LTD") operates the website "NumericaIdeas"
                at https://www.numericaideas.com and any related services provided by Ni LTD.
                We take your privacy seriously. To better protect your privacy,
                we provide this privacy policy notice explaining the way your personal information
                is collected and used. </p>
              <p><strong>Collection of Routine Information</strong>
              </p>
              <p>
                This website track basic information about its visitors.
                This information includes, but is not limited to, IP addresses, browser details,
                timestamps and referring pages. None of this information can personally identify specific
                visitors to this website. The information is tracked
                for routine
                administration and maintenance purposes. </p>
              <p><strong>Cookies</strong>
              </p>
              <p>
                Where necessary, this website uses cookies to store information about a visitor’ s
                preferences and history to better serve the visitor and/or present the
                visitor with customized content. </p>
              <p><strong>Advertisement and Other Third Parties</strong>
              </p>
              <p>
                Advertising partners and other third parties may use cookies, scripts and/or web beacons
                to track
                visitor activities on this website to display advertisements
                and other useful information. Such tracking is done directly by the third parties through
                their
                servers and is subject to their privacy policies. This website has no access or
                control over these cookies, scripts and/or web beacons that may be used by third
                parties. </p>
              <div>
                <p>
                  We have included links on this website
                  for your use and reference.
                  We are not responsible
                  for the privacy policies on these websites. You
                  should be aware
                  that the privacy policies of these websites may differ from our own. </p>
                <p>
                  Link to the privacy policy of third - party service providers used
                  by the website
                </p>
                <ul>
                  <li><a href="https://policies.google.com/privacy" target="_blank " rel="noopener noreferrer ">Google Analytics</a></li>
                  <li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">YouTube</a></li>
                  <li><a href="https://twitter.com/privacy" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                  <li><a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                  <li><a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                  <li><a href="https://discord.com/privacy" target="_blank" rel="noopener noreferrer">Discord</a></li>
                  <li><a href="https://help.disqus.com/en/articles/1717103-disqus-privacy-policy" target="_blank" rel="noopener noreferrer">Disqus</a></li>
                </ul>
              </div>
              <p><strong>Security</strong></p>
              <p>
                The security of your personal information is important to us, but remember that no
                method of transmission over the Internet, or method of electronic storage, is 100 %
                secure.
                While we strive to use commercially acceptable means to protect your personal
                information,
                we cannot guarantee its absolute security. </p>
              <p><strong>Changes To This Privacy Policy</strong>
              </p>
              <p>
                This Privacy Policy is effective as of 2023 - 03 - 24 and will remain in
                effect except concerning
                any
                changes in its provisions in the future, which will be in effect immediately
                after being posted on this
                page.
                We reserve the right to update or change our Privacy Policy at any time and
                you
                should check this Privacy Policy periodically. If we make any material changes
                to this Privacy
                Policy,
                we will notify you either through the email address you have provided us or by
                placing a prominent notice on our website. </p>
              <p><strong>Contact Information</strong>
              </p>
              <p>
                For any questions or concerns regarding the privacy policy,
                please send us an email at <a href="mailto:ping@numericaideas.com">ping@numericaideas.com</a>. </p>
              <p>
                Our Address: Office 4 219 Kensington High Street, Kensington,
                London, England, W8 6 BD </p>
      
            </article>
          </div>
          <Footer/>
        </ParticleComponent>
      </Layout>
    ); 
  }
}

export default PrivacyPolicyPage;
